export function scrollToHash(container = document) {
  let anchors = container.querySelectorAll('header a[href*="#"]');
  const offset = 53;
  for (let anchor of anchors) {
    const href = anchor.getAttribute('href');
    const targetID = '#' + href.split('#')[1];
    const target = href !== '#' && container.querySelector(targetID);
    if (!target) continue;
    anchor?.addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({
        top: window.scrollY + target.getBoundingClientRect().top - offset,
        behavior: 'smooth'
      })
    })
  }
}
