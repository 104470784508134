import {gsap} from 'gsap';

export function accordionToggle(container = document) {
  const blocks = container.querySelectorAll('.job_position_accordion_block');
  for (let block of blocks) {
    const accordions = block.querySelectorAll('.accordion-content');
    gsap.defaults({duration: 0.7, ease: 'linear'});
    accordions.forEach((accordion) => {
      const accordionHead = accordion.querySelector('.head');
      const accordionBody = accordion.querySelector('.body');
      accordionHead?.addEventListener('click', (e) => {
        if (!accordionBody) {
          return;
        }
        const isOpened = accordion?.classList.toggle('active');
        if (!isOpened) {
          gsap.to(accordionBody, {height: 0});
        } else {
          gsap.to(Array.from(accordions).map(otherFaq => {
            const otherFaqBody = otherFaq.querySelector('.body');
            if (otherFaqBody && accordion !== otherFaq) {
              otherFaq?.classList.remove('active');
              gsap.set(otherFaq, {zIndex: 1});
            }
            return otherFaqBody;
          }), {height: 0});
          gsap.set(accordion, {zIndex: 2});
          gsap.to(accordionBody, {height: 'auto'});
        }
      });
    });
  }
}
