export function setFormFunctionality(container = document) {
  const forms = container.querySelectorAll('.gform_wrapper');
  const uploadInputsContainer = container.querySelectorAll('.ginput_container_fileupload');
  for (let uploadInput of uploadInputsContainer) {
    const inputDesc = uploadInput.querySelector('.gform_drop_instructions');
    const inputLabel = uploadInput.previousElementSibling;
    if (inputLabel.textContent === '') {
      inputDesc.textContent = 'Drag or Select Files to Upload';
    } else {
      inputDesc.textContent = inputLabel.textContent;
    }
    inputLabel.remove();
  }
}
