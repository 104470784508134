import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function stickySidebar(container) {
  const sectionsWrapper = container.querySelector('.blog-wrapper');
  const sideBar = container.querySelector('.time-and-social-icons');
  if(!sideBar) return;
  container.querySelector('.blocks-gallery-grid')?.classList.add('reset-ul');
  ScrollTrigger.matchMedia({
    '(min-width:600px)': () => {
      ScrollTrigger.create({
        trigger: sectionsWrapper,
        start: "top 100px",
        // pin for the difference in heights between the content and the sidebar
        end: self => "+=" + (sectionsWrapper.offsetHeight - self.pin.offsetHeight),
        pin: sideBar,
        pinSpacing: false,
      });
    }
  });
}
