import './styles/style.scss';
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import header from './blocks/header_block';
import hero from './blocks/hero_block';
import {initBlocks} from './blocks';
import {copyLink} from './scripts/general/copy-link';
import {accordionToggle} from './scripts/general/accordion-toggle';
import {breakLine} from './scripts/functions/breakLine';
import {stickySidebar} from './scripts/functions/stickySidebar';
import {getHeightOfViewPort} from './scripts/functions/getHeightOfViewPort';
import {setFormFunctionality} from './scripts/general/set-form-functionality';
import {scrollToHash} from './scripts/general/scroll-to-hash';
import {backToTop} from './scripts/general/back-to-top';

const reInvokableFunction = async (container = document) => {
  container.querySelector('header') && await header(container.querySelector('header'));
  await initBlocks(container);
  container.querySelector('.hero_block') && await hero(container.querySelector('.hero_block'));
  setFormFunctionality(container);
  copyLink(container);
  scrollToHash(container);
  accordionToggle(container);
  // breakLine(container);
  stickySidebar(container);
  getHeightOfViewPort(container);
  backToTop(container);

  ScrollTrigger.refresh(false);
};
let loaded = false;

async function onLoad() {
  gsap.config({
    nullTargetWarn: false,
  });
  if (document.readyState === 'complete' && !loaded) {
    loaded = true;
    gsap.registerPlugin(ScrollTrigger);
    await reInvokableFunction();
    document.body.classList.add('loaded');
    let currentURL = window.location.href;
    let blockID = currentURL.split('#');
    if (blockID && blockID[1]) {
      let block = document.querySelector('#' + blockID[1]);
      document.body.classList.remove('loaded');
      setTimeout(() => {
        window.scrollTo(0, block.getBoundingClientRect().top + window.scrollY - 53)
        document.body.classList.add('loaded');
      }, 400)
    }
  }
}

onLoad();

document.onreadystatechange = function () {
  onLoad();
};
