import './index.html';
import './style.scss';
import {imageLazyLoading} from "../../scripts/functions/imageLazyLoading";
import {animations} from "../../scripts/general/animations";

const heroBlock = async (block) => {

  const watchVideo = block.querySelector('.watch-video');
  const customModalEl = block.querySelector('.hero-video-modal');
  const vimeoYoutubeVideo = block.querySelector('iframe');
  // region load iframe viemo player if there any iframe
  let player = '';
  let youtubePlayer = '';
  let vimeoScriptTag = document.createElement('script');
  if (vimeoYoutubeVideo && vimeoYoutubeVideo.src.includes('vimeo')) {
    vimeoScriptTag.src = "https://player.vimeo.com/api/player.js";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(vimeoScriptTag, firstScriptTag);
    vimeoYoutubeVideo.setAttribute('allow', 'autoplay')
    vimeoScriptTag.addEventListener('load', function () {
      player = new Vimeo.Player(vimeoYoutubeVideo);
    });
  } else if (vimeoYoutubeVideo && vimeoYoutubeVideo.src.includes('youtube')) {
    youtubePlayer = vimeoYoutubeVideo.src;
  }
  // endregion load iframe viemo player if there any iframe

  if (customModalEl) {
    const closeModal = () => {
      // region allow page scroll
      document.documentElement.classList.remove('modal-opened');
      // endregion allow page scroll
      if (vimeoYoutubeVideo && vimeoYoutubeVideo.src.includes('vimeo')) {
        player.pause();
      } else if (vimeoYoutubeVideo && vimeoYoutubeVideo.src.includes('youtube')) {
        vimeoYoutubeVideo.src = youtubePlayer + '?autoplay=0';
      }
      customModalEl.querySelector('video') ? customModalEl.querySelector('video').pause() : '';
      customModalEl.classList.remove('active');
    }

    const keyHandler = (e) => {
      if (e.key === 'Escape') {
        closeModal();
        // clearModal();
      }
    }
    window.addEventListener('keydown', keyHandler)

    customModalEl.addEventListener('click', () => {
      closeModal();
    });
    customModalEl.querySelector('.custom-modal-inner').addEventListener('click', function (e) {
      e.stopPropagation();
    });
    customModalEl.querySelector('.close-modal').addEventListener('click', function (e) {
      closeModal();
      // clearModal();
    });


    watchVideo?.addEventListener('click', (e) => {
      e.preventDefault();
      //region handle viemo player and youtube
      if (vimeoYoutubeVideo && vimeoYoutubeVideo.src.includes('youtube')) {
        vimeoYoutubeVideo.src = youtubePlayer + '?autoplay=1';
      } else if (vimeoYoutubeVideo && vimeoYoutubeVideo.src.includes('vimeo')) {
        player.play().then(function(){
        })
          .catch(function(error) {
            switch (error.name) {
              case 'PasswordError':
                break;
              case 'PrivacyError':
                break;
              default:
                break;
            }
          });
        // vimeoScriptTag.addEventListener('loadeddata', function () {
        //
        // })
      }
      //endregion handle viemo player and youtube
      // region prevent page scroll
      document.documentElement.classList.add('modal-opened');
      // endregion prevent page scroll
      customModalEl.classList.add('active');
      customModalEl.querySelector('video') ? customModalEl.querySelector('video').play() : '';
    })
  }

  animations(block);
  imageLazyLoading(block);
}

export default heroBlock;

