import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {getElementsForAnimation} from '../../functions/getElementsForAnimation';

gsap.registerPlugin(ScrollTrigger);


export function inViewAnimations(container = document) {
  const elementsHorizontal = getElementsForAnimation(container, '.iv-st-from-left, .iv-st-from-right');
  ScrollTrigger.batch(elementsHorizontal, {
    onEnter: batch => {
      gsap.fromTo(batch, {
        autoAlpha: 0,
        xPercent: (_, target) => 100 * (target.classList.contains('iv-st-from-right') ? 1 : -1),
      }, {
        duration: .7,
        autoAlpha: 1,
        xPercent: 0,
        ease: 'power1.out',
        stagger: .1,
        clearProps: 'transform',
      });
    },
    onEnterBack: batch => {
      gsap.fromTo(batch, {
        autoAlpha: 0,
        xPercent: (_, target) => 100 * (target.classList.contains('iv-st-from-right') ? 1 : -1),
      }, {
        duration: .7,
        autoAlpha: 1,
        xPercent: 0,
        ease: 'power1.out',
        stagger: .1,
        clearProps: 'transform',
      });
    },
    start: 'top 90%',
    once: true,
  });
  const elementsVertical = getElementsForAnimation(container, '.iv-st-from-top, .iv-st-from-bottom');
  ScrollTrigger.batch(elementsVertical, {
    onEnter: batch => {
      gsap.fromTo(batch, {
        autoAlpha: 0,
        y: (_, target) => 80 * (target.classList.contains('iv-st-from-bottom') ? 1 : -1),
      }, {
        duration: .7,
        autoAlpha: 1,
        y: 0,
        ease: 'power1.out',
        stagger: .1,
        clearProps: 'transform',
      });
    },
    onEnterBack: batch => {
      gsap.fromTo(batch, {
        autoAlpha: 0,
        y: (_, target) => 80 * (target.classList.contains('iv-st-from-bottom') ? 1 : -1),
      }, {
        duration: .7,
        autoAlpha: 1,
        y: 0,
        ease: 'power1.out',
        stagger: .1,
        clearProps: 'transform',
      });
    },
    start: 'top 90%',
    once: true,
  });
}

ScrollTrigger.addEventListener('refreshInit', () => {
  console.log('refresh');
  gsap.set('.iv-st-from-top, .iv-st-from-bottom, .iv-st-from-top-f, .iv-st-from-bottom-f', {
    y: 0
  })
  gsap.set('.iv-st-zoom', {scale: 1})
});
