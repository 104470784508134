export function copyLink(container = document) {
  let copied = container.querySelectorAll('.icon-copied');
  
  
  copied.forEach((copy) => {
    copy?.addEventListener('click', function (e) {
      e.preventDefault();
      let dummy = document.createElement('input'),
        text = window.location.href;
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      this.classList.add('active');
      setTimeout(function () {
        copy.classList.remove('active');
      }, 3000);
    });
    
  });
  
}
