import './index.html';
import './style.scss';
import {gsap} from 'gsap'

export default async (header) => {
  const navigation = header.querySelector("nav")
  const menuBtn = header.querySelector(".menu-icon")
  const headerLinks = header.querySelectorAll(".links-wrapper .link")
  const headerSocial = header.querySelectorAll(".social-media .social-icon")


  const sticky = header.offsetTop;

  function stickyFunction() {
    if (window.scrollY > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }

  stickyFunction();
  window.addEventListener('scroll', stickyFunction);

  // When Open Menu Animation Start


  // Animation
  const openAnimation = gsap.timeline({
    paused: true,
    defaults: {
      ease: "power2",
      duration: .3
    }
  })
    .from(navigation, {
      opacity: 0
    })
    .from(headerLinks, {
      yPercent: -100,
      opacity: 0,
      stagger: .1
    }, "<50%")
    .from(headerSocial, {
      yPercent: -100,
      opacity: 0,
      stagger: .05
    }, "<50%");

  // When Click

  menuBtn?.addEventListener("click", function (e) {
    e.preventDefault()
    let active = header.classList.toggle("open");
    if (active) {
      openAnimation.play();
    } else {
      openAnimation.reverse();
    }
  })


  header.querySelectorAll('nav .links-wrapper .link a').forEach(link => link.addEventListener('click', () => {
    header.classList.remove('open');
    openAnimation.reverse();
  }))


};

